.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  overflow: hidden;
}

.App-header {
  position: relative;
}

a {
  color: black;
  text-decoration: none; /* no underline */
}

@font-face {
  font-family: 'LuxoraGoretsk';
  src: url('./assets/fonts/OTF/LuxoraGrotesk-Book.otf') format('opentype'),
      url('./assets/fonts/TTF/LuxoraGrotesk-Book.ttf') format('truetype');
}

@font-face {
  font-family: 'LuxoraGoretskThin';
  src: url('./assets/fonts/OTF/LuxoraGrotesk-Thin.otf') format('opentype'),
      url('./assets/fonts/TTF/LuxoraGrotesk-Thin.ttf') format('truetype');
}

.each-slide {
  display: flex;
  justify-content: flex-end; /* Slaytın sağ kenarına hizala */
}

.word {
  padding: 20px; /* Kelimenin etrafına boşluk ekleyin */
  animation: slideInRight 0.5s ease-in-out; /* Sağdan gelen animasyon */
}

body {
  font-family: 'LuxoraGoretsk';
}

/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  left: 36px;
  top: 36px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #373a47;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #a90000;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
  top: 0%;
  left: 0;
}

/* General sidebar styles */
.bm-menu {
  background: #000000e5;
  padding: 2.5em 0.5em 0;
  font-size: 1.5em;
  font-family: LuxoraGoretsk;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  display: inline-block;
  margin-top: 10%;
  color: white;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
  top: 0%;
}

/* Styling of close button */
.bm-cross-button span {
  top: 14px !important;
  right: 20px !important;
} 

.bm-cross-button {
  width: 60px !important;
  height: 60px !important;
  right: 0px !important;
  top: 0px !important;
}

/* Swiper */
.swiper {
  width: 100%;
  height: 100%;
}

.swiper .swiper-button-prev {
  width: 10px;
  scale: 0.8;
}

.swiper .swiper-button-next {
  width: 10px;
  scale: 0.8;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: transparent;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}