video {
    right: 0;
    bottom: 0;
    min-width: 100%; 
    min-height: 100%;
}

.navigation_bar {
    background-color: transparent;
    height: 140px;
    width: 100%;
    z-index: 999;
    position: absolute;
    top: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.navigation_buttons {
    margin-right: 3%;
}

.content_body {
    z-index: 999;
    position: absolute;
    bottom: 5%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 94%;
}

.welcome_text {
    position: absolute;
    left: 5%;
    top: 22%;
    font-size: 47px;
}

.aboutus_text {
    position: fixed;
    left: 5%;
    top: 17%;
    font-size: 40px;
}

.first_h1 {
    margin-bottom: 0;
    margin-bottom: -3vh;
}

.second_h1  {
    margin-top: 0;
    margin-bottom: 0;
}

h6 {
    margin-top: 0;
    margin-bottom:0;
}

.cards {
    background-color: #c9c9c94a;
    width: 32%;
    height: 34vh;
    border-radius: 60px;
    backdrop-filter: blur(5px);
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
}

.logo {
    width: 250px;
    height: 125px;
    position: absolute;
    left: 4%;
    top: 0;
}

.hamburgerLogo {
    width: 150px;
    height: 75px;
    position: fixed;
    right: 4%;
    top: 16px;
    z-index: 1;
}

.right_buttons_container {
    position: absolute;
    right: 4%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.demo_button {
    width: 150px;
    height: 40px;
    background-color: #fff;
    border-radius: 30px;
    border: none;
    pointer-events: none;
    margin-top: 80px;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.3);
}

.order_button:hover {
    background-color: blue;
    color:#fff;
}

.logo_disable {
    pointer-events: none;
}

.horizontal_team {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0px 10px 0px 10px;
    /* padding: 18% 10px 10px 10px; */
}

.team_member {
    font-weight: bold;
    padding-left: 10px;
}

.team_function {
    margin-left: 10px;
}

.subText  {
    font-family: 'LuxoraGoretskThin', sans-serif !important;
    position: absolute;
}

.contact_body {
    z-index: 999;
    position: absolute;
    bottom: 30%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 94%;
}

.contact_container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 90%;
}

iframe.contact_iframe iframe {
    font-family: 'LuxoraGoretskThin', sans-serif !important;
}

@font-face {
    font-family: 'Poppins';
    src: url('../../assets/fonts/Poppins-SemiBold.ttf') format('truetype');
}

.animation_container {
    overflow: hidden;
    position: absolute;
    transition: all 0.5s ease-in-out;
    font-family: Poppins;
    top: 29.25%;
    left: 18.7%;
    font-size: 35px;
}  

.responsiveCard {
    position: absolute;
    z-index: 9999999;
    width: 470px;
    height: 320px;
    animation-delay: 1.5s;
    animation-duration: 1s;
    bottom: 50%;
    background-color: #c9c9c94a;
    border-radius: 60px;
    backdrop-filter: blur(5px);
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
}

@media only screen and (max-width: 277px) {
    .first_h1 span {
        font-size: 40px !important;
    }

    .subText {
        height: 50px !important;
        width: 260px !important;
        font-size: 19px !important;
    }
    
    .second_h1 span {
        margin-top: 7px !important;
        font-size: 30px !important;
    }
    .responsiveCard {
        width: 232px !important;
        height: 240px !important;
    } 

    .demo_button {
        margin-top: 90px !important;
    } 

    .horizontal_team {
        margin: 12% 15% 0 10% !important;
        font-size: 13px;
    }
    .responsive_video {
        scale: 4 !important;
    }
}
  

@media only screen and (min-width: 278px) and (max-width: 385px) {
    .first_h1 span {
        font-size: 25px !important;
    }

    .subText {
        height: 100px;
        width: 310px;
        font-size: 20px;
    }
    
    .second_h1 span {
        margin-top: 7px !important;
        font-size: 25px !important;
    }
    .responsiveCard {
        width: 255px !important;
        height: 260px;
    } 

    .demo_button {
        margin-top: 110px !important;
    } 

    .horizontal_team {
        margin: 12% 15% 0 10% !important;
        font-size: 15px;
    }
}
@media only screen and (min-width: 386px) and (max-width: 404px) {

    .horizontal_team {
        margin: 10% 10% 0 6%;
        font-size: 15px;
    }

    .subText {
        height: 100px;
        width: 323px !important;
        font-size: 18px;
    }

    .responsiveCard {
        width: 340px;
        height: 230px;
    } 

    .first_h1 span {
        font-size: 38px !important;
    }
    
    .second_h1 span {
        margin-top: 30px !important;
        font-size: 30px !important;
    }

    .demo_button {
        margin-top: 120px;
    } 
}

/* Mobil ekranlar için stil */
@media only screen and (min-width: 405px) and (max-width: 600px) {

    .horizontal_team {
        margin: 10% 10% 0 8%;
        font-size: 14px;
    }

    .subText {
        height: 100px;
        width: 376px !important;
        font-size: 20px;
    }

    .responsiveCard {
        width: 340px;
        height: 230px;
    } 

    .first_h1 span {
        font-size: 38px !important;
    }
    
    .second_h1 span {
        margin-top: 30px !important;
        font-size: 30px !important;
    }

    .demo_button {
        margin-top: 120px;
    } 
}

@media only screen and (min-width: 601px) and (max-width: 767px) {

    .horizontal_team {
        margin: 5% 10% 10% 10%;
    }

    .responsiveCard {
        width: 90%;
        height: 250px;
    } 

    .first_h1 span {
        font-size: 70px !important;
    }
    
    .second_h1 span {
        margin-top: 30px !important;
        font-size: 50px !important;
    }

    .demo_button {
        margin-top: 100px;
    } 
}
  /* Tablet ekranlar için stil */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
    .horizontal_team {
        margin: 16% 10px 10px 10px;
    }

    .first_h1 span {
        font-size: 80px !important;
        margin-bottom: 10px !important;
    }
    
    .second_h1 span {
        font-size: 60px !important;
    }
}

@media only screen and (min-width: 1024px) and (max-width: 1038px){
    .second_h1 span {
        font-size: 87px !important;
    }
    .horizontal_team {
        margin: 16% 10% 10% 10%;
    }
}

  /* Dizüstü bilgisayarlar için stil */
/* @media only screen and (min-width: 1024px) and (max-width: 1439px) { */
    /* Dizüstü için özel stiller burada */
/* } */
  /* Masaüstü ekranlar için stil */
/* @media only screen and (min-width: 1440px) { */
    /* Masaüstü için özel stiller burada */
/* } */

@media screen and (max-width:1608px) {
    .animation_container {
        left: 19.7%;
    }
}

@media screen and (max-width:1578px) {
    .animation_container {
        left: 36.7%;
    }
}

@media screen and (max-width:1554px) {
    .animation_container {
        left: 19.7%;
    }
}

@media screen and (max-width:1491px) {
    .animation_container {
        font-size: 25px;
        left: 20.7%;
        top: 34.25%;
    }   
}

@media screen and (max-width:1302px) {
    .animation_container {
        left: 22.7%;
        top: 34.25%;
    }   
}

@media screen and (max-width:1215px) {
    .animation_container {
        font-size: 20px;
        left: 23.7%;
        top: 38.25%;
    }
}

@media screen and (max-width:1116px) {
    .animation_container {
        font-size: 12px;
        left: 25.7%;
        top: 43.25%;
    }
}

@media screen and (max-width:1060px) {
    .animation_container {
        font-size: 12px;
        left: 25.7%;
        top: 43.25%;
    }
}
